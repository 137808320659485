import React, { useState, useEffect } from 'react'
import { useLocation } from "react-router-dom"
import { connect } from "react-redux"
import axios from '../../../AxiosConfig'
import jwt_decode from 'jwt-decode'
import { setWebsiteData } from '../../../features/websiteData/websiteDataSlice'
import { showAppToast } from '../../../features/utilities/appToastSlice'
import { hideAddBlockModal } from '../../../features/modal/offcanvasSlice'
import Modal from 'react-bootstrap/Modal'

const BlockListModal = ({
    setWebsiteData,
    showAppToast,
    showAddBlockModal,
    blockName,
    hideAddBlockModal,
    CompleteJSON
}) => {
    // console.log(showAddBlockModal, blockName)
    const [blockList, setBlockList] = useState([{}])
    const [preloader, setPreloader] = useState(false)
    const [index,setIndex] = useState(-1)

    useEffect(() => {
        handleBlockList()
    }, [])

    const handleBlockList = () => {
        axios.put('/block/list/' + selectedDomain).then(res=>{
            // console.log('RESPONSE', res.data.data)
            setBlockList(res.data.data)
            // console.log(blockList)
        })
    }

    const token = localStorage.getItem('token')
    const tokenData = (token) ? jwt_decode(token) : false
    const selectedDomain = tokenData.SelectedDomain.Domain

    const search = useLocation().search
    let url = ''
    url = new URLSearchParams(search).get('url')
    if (url === null) {
        url = localStorage.getItem('previousPage')
    } else {
        url = new URLSearchParams(search).get('url')
        localStorage.setItem('previousPage', url)
    }

    const fetchWebsiteJSON = () => {
        const webDoc = CompleteJSON; //localStorage.getItem('website')
        const res = webDoc; //(webDoc) ? JSON.parse(atob(webDoc)) : false
        if (res) {
          return res
        } else {
          return false
        }
    }

    const websiteJSON = fetchWebsiteJSON()

    const handleAddNewBlock = (styleID, index) => {
        setIndex(index)
        setPreloader(true)
        let pageId = ''
        Object.keys(websiteJSON.appearance.pages).map((value, key) => {
            // console.log(value, key)
            if (websiteJSON.appearance.pages[value].url === url) {
                pageId = value
            }
        })
        if(tokenData.userInfo.RoleID != 'Moderator') {
            axios.post('/block/'+ selectedDomain + '/'+ pageId + '?Style=' + styleID + '&StyleCode=' + styleID + '_DFL1&Position=' + blockName).then(res=>{

                if (res.data.message === 'Success') {
                    showAppToast({ toastMessage: 'BLOCK ADDED SUCCESSFULLY', background: 'success', timeout: '1000', icon:'checkmark-circle-outline', position: 'toast-center' })
                    setWebsiteData(res.data.data)
                    hideAddBlockModal()
                    setPreloader(false)
                } else {
                    showAppToast({ toastMessage: 'FAIL', background: 'danger', timeout: '1000', icon:'close-circle-outline', position: 'toast-center' })
                    hideAddBlockModal()
                    setPreloader(false)
                }
            })
        } else {
            const selectedCategoryID = tokenData.SelectedDomain.ID
            const categoryPageID = localStorage.getItem('selectedPageID')
            // console.log(selectedCategoryID, pageId, styleID, blockName, categoryPageID)
            axios.post('/block/'+ selectedCategoryID + '/'+ pageId + '?Style=' + styleID + '&StyleCode=' + styleID + '_DFL1&Position=' + blockName + '&PageID=' + categoryPageID).then(res=>{
                // console.log(res)
                if (res.data.message === 'Success') {
                    showAppToast({ toastMessage: 'BLOCK ADDED SUCCESSFULLY', background: 'success', timeout: '1000', icon:'checkmark-circle-outline', position: 'toast-center' })
                    setWebsiteData(res.data.data)
                    hideAddBlockModal()
                    setPreloader(false)
                } else {
                    showAppToast({ toastMessage: 'FAIL', background: 'danger', timeout: '1000', icon:'close-circle-outline', position: 'toast-center' })
                    hideAddBlockModal()
                    setPreloader(false)
                }
            })
        }
    }

    return (
        <div>
            <Modal className="modal fade modalbox" id="BlockListview" data-bs-backdrop="false" tabindex="-1" role="dialog" show={showAddBlockModal} fullscreen={true}>
                {/* <Modal.Dialog className="modal-dialog" role="document"> */}
                    <div className="modal-content">
                        <Modal.Header className="modal-header">
                            <h5 className="modal-title">Add Block</h5>
                            <span className="text-primary" onClick={() => {hideAddBlockModal()}} style={{cursor: 'pointer'}}>Close</span>
                        </Modal.Header>
                        <Modal.Body className="modal-body p-0">
                            <ul className="listview image-listview flush mb-2">
                                {blockList.map((value, key) =>
                                    <li className="custom-line" key={key}>
                                        <a href="#!" className="item" onClick={() => {handleAddNewBlock(value.Name, key)}}>
                                            <div className="icon-box bg-secondary">
                                                <ion-icon name={value.Icon}></ion-icon>
                                            </div>
                                            <div className="in">
                                                <div>{value.Title}</div>
                                                {(preloader && index === key) &&
                                                    // <div className="spinner-border text-secondary" role="status"></div>
                                                    <button className="btn text-secondary" type="button" disabled>
                                                        <span className="spinner-border spinner-border-sm me-05" role="status" aria-hidden="true"></span>
                                                        Adding...
                                                    </button>
                                                }
                                                {(!preloader && index === key) &&
                                                    <span className="text-muted">Add</span>
                                                }
                                            </div>
                                        </a>
                                    </li>
                                )}
                            </ul>
                        </Modal.Body>
                    </div>
                {/* </Modal.Dialog> */}
            </Modal>
        </div>
    )
}

const mapStateToProps = ({ offcanvas, websiteData }) => {
    const {
        showAddBlockModal,
        blockName
    } = offcanvas
    const {
        CompleteJSON
    } = websiteData
    return {
        showAddBlockModal,
        blockName,
        CompleteJSON
    }
}
  
  const mapDispatchToProps = {
    setWebsiteData,
    showAppToast,
    hideAddBlockModal
  }

export default connect(mapStateToProps, mapDispatchToProps)(BlockListModal)
