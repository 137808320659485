import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { connect } from "react-redux"
import { updateUtilities } from '../../../features/utilities/utilitiesSlice'
import { hideSwitchModal, addPageModal, showNavigationOffcanvas, showDeleteModal, showClonePageModal, setADDClonePageProps } from '../../../features/modal/offcanvasSlice'
import { setWebsiteData } from '../../../features/websiteData/websiteDataSlice'
import moment from "moment"
import Modal from 'react-bootstrap/Modal'
import jwt_decode from 'jwt-decode'
import axios from '../../../AxiosConfig'

export function extractTitles(data, key) {
    const titles = [];

    function traverse(node) {
        titles.push(node[key]);
        if (node.children && node.children.length > 0) {
            node.children.forEach(child => {
                traverse(child);
            });
        }
    }

    data.forEach(item => {
        traverse(item);
    });

    return titles;
}

function SwitchModal({
    selectedDomain,
    websites,
    domains,
    type,
    isSwitchModalVisible,
    hideSwitchModal,
    category,
    setWebsiteData,
    addPageModal,
    switchModalMessage,
    blockID,
    showNavigationOffcanvas,
    showDeleteModal,
    appearance,
    websiteAppearance,
    showClonePageModal,
    setADDClonePageProps,
    CompleteJSON,
    authToken
}) {
    const [backupSiteMapList, setBackupSiteMapList] = useState([])
    const [sitemapList, setSitemapList] = useState([])
    const [currentPageName, setCurrentPageName] = useState('')
    let navigate = useNavigate()
    const pathname = window.location.pathname
    const search = useLocation().search
    const [searchIn, setSearchIn] = useState('');

    const token = authToken
    const tokenData = (token) ? jwt_decode(token) : false
    const roleID = tokenData.userInfo.RoleID
    const selectedCategoryID = tokenData.SelectedDomain.ID

    const [showAllPages, setShowAllPages] = useState(false);
    const [showAllPagesList, setShowAllPagesList] = useState([]);

    useEffect(() => {
        if (isSwitchModalVisible && appearance.pages && sitemapList.length > 0) {
            const allpageList = Object.keys(appearance.pages).map((page, i) => {
                return {
                    "id": `key${i}`,
                    "title": appearance.pages[page].properties.title,
                    "page": page,
                    "type": "",
                    "url": appearance.pages[page].url,
                    "icon": "",
                    "children": []
                }
            })
            const allHeadersPages = extractTitles(sitemapList, 'page');
            const filteredAllPageList = allpageList.filter((page) => !allHeadersPages.includes(page.page))
            // console.log({allpageList, allHeadersPages, filteredAllPageList});
            setShowAllPagesList(filteredAllPageList)
        }

        if(!isSwitchModalVisible) {
            setSearchIn('')
        }
    }, [isSwitchModalVisible, appearance, sitemapList]);

    const fetchWebsiteJSON = () => {
        const webDoc = CompleteJSON
        const res = webDoc; // (webDoc) ? webDoc : false
        if (res) {
            return res
        } else {
            return false
        }
    }
    const websiteJSON = fetchWebsiteJSON()
    let sitemapData
    let activePage
    let websiteDataAppearance
    if (websiteJSON !== undefined && websiteJSON !== false && websiteJSON !== null) {
        sitemapData = websiteJSON.appearance.navigation.header
        activePage = websiteJSON.appearance.properties.defaultPage
        websiteDataAppearance = websiteJSON.appearance
    }

    const report = new URLSearchParams(search).get('report')
    let url = ''
    url = new URLSearchParams(search).get('url')
    if (url === null) {
        url = localStorage.getItem('previousPage')
    } else {
        url = new URLSearchParams(search).get('url')
        localStorage.setItem('previousPage', url)
    }

    const switchWebsiteDomain = (switchTo, type) => {
        navigate('/load?domain=' + switchTo + '&type=' + type)
        hideSwitchModal()
    }

    const handleParentPrevention = (e) => {
        e.stopPropagation()
    }

    const handleDNSManage = () => {
        navigate('/dns?type=dns')
        hideSwitchModal()
    }

    const getSitemapList = (sitemap) => {
        // console.log({sitemap})
        let list = []
        if (tokenData.userInfo.RoleID !== 'Moderator') {
            setSitemapList(sitemap)
        } else {
            // console.log("else")
            let categoryStructureConfig = JSON.parse(decodeURIComponent(escape(atob(localStorage.getItem('category')))))
            // console.log(JSON.parse(categoryStructureConfig.StructureConfig).navigation.header)
            setSitemapList(JSON.parse(categoryStructureConfig.StructureConfig).navigation.header)
            // setSitemapList(list)
            //   axios.get('/page/smartPages/' + selectedCategoryID).then(response => {
            //     if(response.data.data.length == 0) {

            //     } else {
            //       for (let i = 0; i < response.data.data.length; i++) {
            //         response.data.data[i].StructureConfig = JSON.parse(response.data.data[i].StructureConfig)
            //         list[i] = {}
            //         list[i]['page'] = response.data.data[i].StructureConfig.appearance.navigation.header[0].page
            //         list[i]['title'] = response.data.data[i].StructureConfig.appearance.navigation.header[0].title
            //         list[i]['id'] = response.data.data[i].ID
            //       }

            //       console.log(list)
            //       setSitemapList(list)
            //     }
            //   })
        }
    }

    let pageId = ''

    const getPageName = () => {
        websiteDataAppearance && websiteDataAppearance.pages && Object.keys(websiteDataAppearance.pages).map((value, key) => {
            if (websiteDataAppearance.pages[value].url === url) {
                pageId = value
                websiteDataAppearance.navigation.header.map((v, k) => {
                    if (v.hasOwnProperty('children')) {
                        websiteDataAppearance.navigation.header[k].children.map((v1, k1) => {
                            if (v1.page === pageId) {
                                setCurrentPageName(v1.title)
                            }
                        })
                    } else {
                        if (v.page === pageId) {
                            setCurrentPageName(v.title)
                        }
                    }
                })
            }
        })
    }

    useEffect(() => {
        if (appearance !== undefined && appearance.navigation !== undefined && tokenData.userInfo.RoleID !== 'Moderator') {
            setSitemapList(appearance.navigation.header);
            setBackupSiteMapList(appearance.navigation.header);
        }
        //  else {
        //     let categoryStructureConfig = JSON.parse(decodeURIComponent(escape(atob(localStorage.getItem('category')))))
        //     setSitemapList(JSON.parse(categoryStructureConfig.StructureConfig).navigation.header)
        // }
    }, [appearance])


    useEffect(() => {
        getPageName()
        // getSitemapList(sitemapData)
        // console.log(sitemapList)
        if (url !== undefined && url !== null) {
            if (sitemapList.length > 0) {
                {
                    sitemapList.map(value => {
                        if (value.url === url) {
                            setCurrentPageName(value.title)
                        }
                    })
                }
            } else {
                const sitemapData = websiteJSON.appearance ? websiteJSON.appearance.navigation.header : []
                let list = []
                sitemapData.map((value) => {
                    if (!value.hasOwnProperty('children')) {
                        list.push(value)
                    } else {
                        value.children.map(val => {
                            list.push(val)
                        })
                    }
                })
                {
                    list.map(value => {
                        if (value.url === url) {
                            setCurrentPageName(value.title)
                        }
                    })
                }
            }
            // document.getElementById('pageList').value = url
        }
    }, [url])

    const handlePageSwitch = (page, printName, id) => {
        console.log(page, printName, id)
        hideSwitchModal()
        if (roleID == 'Moderator') {
            axios.get('/page/smartPages/' + selectedCategoryID).then(response => {
                if (response.data.data.length == 0) {
                } else {
                    for (let i = 0; i < response.data.data.length; i++) {
                        if (page === response.data.data[i].PageID) {
                            let categoryPageJson = {}
                            let categoryJson = {}
                            categoryJson = JSON.parse(response.data.data[i].StructureConfig)
                            //   console.log(categoryJson)
                            const pageUrl = categoryJson.appearance.pages['SM_PAGE1'].url
                            categoryPageJson = btoa(unescape(encodeURIComponent(response.data.data[i].StructureConfig)))
                            // localStorage.setItem('website', categoryPageJson)
                            localStorage.setItem('selectedPageID', response.data.data[i].ID)
                            setWebsiteData(categoryJson)
                            navigate(
                                '/build?url=' + pageUrl
                            )
                        }
                    }
                }
            })
        } else {
            const pageUrl = websiteJSON.appearance.pages[page]?.url
            console.log(pageUrl)
            if(pageUrl) {
                navigate('/build?url=' + pageUrl)
                setCurrentPageName(printName)
            }
        }
    }

    const handleNavigationReorder = () => {
        showNavigationOffcanvas({ isNavigationVisible: true, blockID: blockID, triggerMessage: '' })
        hideSwitchModal()
    }

    const handleDeletePage = (e, pageID) => {
        e.stopPropagation()
        hideSwitchModal();
        // console.log(appearance)
        if (appearance) {
            Object.keys(appearance['pages']).map(key => {
                if (key === pageID) {
                    showDeleteModal({ isDeleteVisible: true, deleteMessage: `Are your sure? You want to delete this page?`, triggerMessage: 'page', deleteID: key })
                }
            })
        }
    }

    const handleClonePageModal = (pageID) => {
        showClonePageModal({ isClonePageModalVisible: true })
        setADDClonePageProps({ 'CloneOrAdd': 'Clone', 'Copies': 1, 'pageID': pageID })
        hideSwitchModal()
    }

    function searchByText(data, searchText) {
        const results = [];

        function traverse(node) {
            if (node?.title?.toLowerCase().includes(searchText?.toLowerCase()) || node?.url?.toLowerCase().includes(searchText?.toLowerCase())) {
                results.push(node);
            }
            if (node.children && node.children.length > 0) {
                node.children.forEach(child => {
                    traverse(child);
                });
            }
        }

        data.forEach(item => {
            traverse(item);
        });

        return results;
    }

    useEffect(() => {
        if (searchIn.trim().length > 0) {
            const filterList  = searchByText(backupSiteMapList, searchIn);
            setSitemapList(filterList);
        } else {
            setSitemapList(JSON.parse(JSON.stringify(backupSiteMapList)));
        }
    }, [searchIn])
    return (
        <>
            <Modal show={isSwitchModalVisible} centered class="modal fade modalbox" id="switchWebsite" data-bs-backdrop="false" tabindex="-1" role="dialog">
                <div className="modal-content">
                    <Modal.Header style={{ boxShadow: "0 3px 6px 0 rgb(0 0 0 / 10%), 0 1px 3px 0 rgb(0 0 0 / 8%)", padding: '0px' }} className=' bg-primary'>
                        <div className="row w-100 d-flex align-items-center justify-content-between">
                            {/* <div className="webweb-w-10 d-flex justify-content-center">

                            </div> */}
                            <div className="webweb-w-80">
                                <ul className="nav nav-tabs lined" role="tablist">
                                    {/* <li className="nav-item">
                                        <a className={(switchModalMessage === 'switchWebsite') ? 'nav-link active' : 'nav-link'} data-bs-toggle="tab" href="#switchWebsiteTab" role="tab">
                                            Switch Website
                                        </a>
                                    </li> */}
                                    <li className="nav-item">
                                        <a className={(switchModalMessage === 'changePage') ? 'nav-link text-light active justify-content-start h5' : 'nav-link'} data-bs-toggle="tab" href="#changePageTab" role="tab">
                                            <span className='h6 mb-0'>Change Page</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="webweb-w-10 dropdown d-flex justify-content-end text-secondary fs-3">
                                {/* <span className="dropdown">
                                    <a href="/SearchDomain" className="headerButton" style={{cursor:'pointer', color:'inherit'}}>
                                        <ion-icon name="search-outline"></ion-icon>
                                    </a>
                                </span>&nbsp;&nbsp; */}
                                <span className="dropdown">
                                    <span className="headerButton" style={{ cursor: "pointer" }} onClick={() => { hideSwitchModal() }}>
                                        <ion-icon name="close-outline" class="text-light"></ion-icon>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="modal-body p-0">

                        <div className="tab-content mt-0 pb-2">
                            <div className={(switchModalMessage === 'switchWebsite') ? 'tab-pane fade show active' : 'tab-pane fade'} id="switchWebsiteTab" role="tabpanel">
                                <div className="listview-title mt-2 d-flex justify-content-center">
                                    <a href="https://webweb.in/" target="_blank" className="badge bg-primary"><ion-icon name="add-circle-outline" className="fs-6"></ion-icon>&nbsp; Create Website</a>
                                </div>
                                {roleID !== 'Moderator' &&
                                    <>
                                        {websites &&
                                            <ul className="listview image-listview">
                                                {websites.map((websiteName, i) => (
                                                    <span className="listview image-listview" key={i}>
                                                        {selectedDomain === websiteName.Domain &&
                                                            <li>
                                                                <a href="#!" onClick={() => { switchWebsiteDomain(websiteName.Domain, type) }} className="item webweb-bg-secondary">
                                                                    <img src="assets/img/webweb.svg" alt="Brand" className="image" />
                                                                    <div className="in">
                                                                        <div>
                                                                            {websiteName.Domain}
                                                                            <footer>Updated {moment(websiteName.UpdatedOn).startOf('hour').fromNow()}</footer>
                                                                            <a href="#!" data-bs-toggle="modal" data-bs-target="#DialogIconedButtonInline" className="btn btn-text-primary p-0 mb-0 btn-sm" onClick={handleParentPrevention}>Connect Domain <ion-icon name="chevron-forward-outline" className="fs-6"></ion-icon></a>
                                                                        </div>
                                                                        <span className="text-muted">
                                                                            <footer>
                                                                                <span className={(type === 'website') ? 'badge webweb-bg-secondary-badge' : 'badge badge-light'}>{(websiteJSON.appearance && websiteJSON.appearance.properties && websiteJSON.appearance.properties.language === 'en_IN') ? 'English' : 'Other'}</span>
                                                                            </footer>
                                                                        </span>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                        }
                                                        {selectedDomain !== websiteName.Domain &&
                                                            <li>
                                                                <a href="#!" onClick={() => { switchWebsiteDomain(websiteName.Domain, type) }} className={(type === 'website') ? 'item' : 'item'}>
                                                                    <img src="assets/img/webweb.svg" alt="Brand" className="image" />
                                                                    <div className="in">
                                                                        <div>
                                                                            {websiteName.Domain}
                                                                            <footer>Updated {moment(websiteName.UpdatedOn).startOf('hour').fromNow()}</footer>
                                                                            <a href="#!" data-bs-toggle="modal" data-bs-target="#DialogIconedButtonInline" className="btn btn-text-primary p-0 mb-0 btn-sm">Connect Domain <ion-icon name="chevron-forward-outline" className="fs-6"></ion-icon></a>
                                                                        </div>
                                                                        <span className="text-muted">
                                                                            <footer>
                                                                                {/* <span className={(type === 'website') ? 'badge webweb-bg-secondary-badge' : 'badge badge-light'}>{(websiteJSON.appearance.properties.language) ? 'English' : 'Other'}</span> */}
                                                                                <span className={(type === 'website') ? 'badge webweb-bg-secondary-badge' : 'badge badge-light'}>English</span>
                                                                            </footer>
                                                                        </span>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                        }
                                                    </span>
                                                ))}
                                            </ul>
                                        }
                                        {!websites &&
                                            <div className="text-center">
                                                <a href="https://webweb.in/" target="_blank" rel="noreferrer" className="btn btn-primary rounded"><ion-icon name="rocket-outline"></ion-icon> Create Instant Website</a>
                                            </div>
                                        }
                                        {/* <div className="listview-title mt-2">
                                            Manage Domain
                                        </div>
                                        {domains &&
                                            <ul className="listview image-listview">
                                                {domains.map(domainName => (
                                                    <span className="listview image-listview">
                                                        {selectedDomain === domainName.Domain && 
                                                            <li>
                                                                <a href="#!" className={(type === 'domain') ? 'item ' : 'item webweb-bg-secondary'} onClick={handleDNSManage}>
                                                                    <div className="icon-box bg-success">
                                                                        <ion-icon name="globe-outline"></ion-icon>
                                                                    </div>
                                                                    <div className="in">
                                                                        <div>{domainName.Domain}</div>
                                                                        <span className="text-muted">Get Started</span>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                        }
                                                    </span>
                                                ))}
                                            </ul>
                                        } */}
                                        {!domains &&
                                            <div className="text-center">
                                                <a href="https://webweb.in/" target="_blank" rel="noreferrer" className="btn btn-primary rounded"><ion-icon name="globe-outline"></ion-icon> Book Domain</a>
                                            </div>
                                        }
                                    </>
                                }

                                {roleID === 'Moderator' &&
                                    <>
                                        {category &&
                                            <ul className="listview image-listview">
                                                {category.map((categoryName, i) => (
                                                    <span className="listview image-listview" key={i}>
                                                        {selectedDomain === categoryName.CategoryName &&
                                                            <li>
                                                                <a href="#!" onClick={() => { switchWebsiteDomain(categoryName.CategoryName, type) }} className={(type === 'website') ? 'item' : 'item webweb-bg-secondary'}>
                                                                    <img src="assets/img/webweb.svg" alt="Brand" className="image" />
                                                                    <div className="in">
                                                                        <div>
                                                                            {categoryName.CategoryName}
                                                                        </div>
                                                                        <span className="text-muted">
                                                                            <footer>
                                                                            </footer>
                                                                        </span>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                        }
                                                        {selectedDomain !== categoryName.CategoryName &&
                                                            <li>
                                                                <a href="#!" onClick={() => { switchWebsiteDomain(categoryName.CategoryName, type) }} className={(type === 'website') ? 'item' : 'item'}>
                                                                    <img src="assets/img/webweb.svg" alt="Brand" className="image" />
                                                                    <div className="in">
                                                                        <div>
                                                                            {categoryName.CategoryName}
                                                                        </div>
                                                                        <span className="text-muted">
                                                                            <footer>
                                                                            </footer>
                                                                        </span>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                        }
                                                    </span>
                                                ))}
                                            </ul>
                                        }
                                    </>
                                }
                            </div>
                            <div className={(switchModalMessage === 'changePage') ? 'tab-pane fade show active' : 'tab-pane fade'} id="changePageTab" role="tabpanel">
                                <div className='px-3'>
                                    <div className="form-group boxed">
                                        <div className="input-wrapper">
                                            <input type="text" className="form-control ps-5" id="name5" value={searchIn} placeholder="Search pages" onChange={(e) => setSearchIn(e.target.value)} autocomplete="off" />
                                            <i className="input-icon1 position-absolute t-0 mt-1 ms-1 fs-25">
                                                <ion-icon name="search-outline"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="listview-title mt-0 d-flex justify-content-center">
                                    <span className="badge bg-primary" onClick={() => { addPageModal({ showAddPageModal: true }) }} style={{ cursor: 'pointer' }}><ion-icon name="add-circle-outline" className="fs-6"></ion-icon>&nbsp;Add Page</span>&nbsp;
                                    <span className="badge bg-secondary" onClick={handleNavigationReorder} style={{ cursor: 'pointer' }}><ion-icon name="menu-outline" className="fs-6"></ion-icon>&nbsp;Reorder</span>&nbsp;
                                    <span className={showAllPages ? "badge bg-success ms-auto me-0" : "badge border-success text-success ms-auto me-0"} onClick={() => { setShowAllPages(!showAllPages) }} style={{ cursor: 'pointer' }}><ion-icon name="eye-off-outline" className="fs-6"></ion-icon>&nbsp;Hidden Pages</span>
                                </div>
                                <div className={showAllPages ? "d-none" : "maxListHieght"}>
                                    <ul className="listview link-listview">
                                        {sitemapList.map((value, j) => {
                                            return (<>{(value.hasOwnProperty('children') && value.children.length !== 0) &&
                                                <>
                                                    <li key={j}>
                                                        <a href="javascript:void(0)">
                                                            {value.title}
                                                            <span className='d-block' style={{'fontSize': '12px', 'lineHeight' : '14px'}}>/{appearance?.pages[value.page]?.url}</span>
                                                            {/* <span className="text-muted">Manage</span> */}
                                                            {/* <ion-icon name="trash-outline" className="text-danger" onClick={(e) => { handleDeletePage(e, value.page) }}></ion-icon> */}
                                                            <div className='d-flex'>
                                                                <span className="badge bg-light text-dark cursor-pointer border" onClick={() => handleClonePageModal(value.page)}>
                                                                    <ion-icon name="copy-outline" className="text-danger">
                                                                    </ion-icon>
                                                                    <span>&nbsp;&nbsp;Duplicate</span>
                                                                </span>
                                                                <span className="badge bg-danger cursor-pointer ms-1" onClick={(e) => { handleDeletePage(e, value.page) }}>
                                                                    <ion-icon name="trash-outline" className="text-danger">
                                                                    </ion-icon>
                                                                    <span>&nbsp;&nbsp;Delete</span>
                                                                </span>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    {value.children.map((val, i) => {
                                                        return (
                                                            <>
                                                                <li key={i} className={(currentPageName === val.title) ? 'custom-active' : ''}>
                                                                    <a href="javascript:void(0)">
                                                                        <div onClick={() => { handlePageSwitch(val.page, val.title) }}>
                                                                            <ion-icon name="return-down-forward-outline"></ion-icon>&nbsp;&nbsp;&nbsp;&nbsp;{val.title}
                                                                            <span className='d-block' style={{'fontSize': '12px', 'lineHeight' : '14px'}}>/{appearance?.pages[val.page]?.url}</span>
                                                                        </div>
                                                                        <div className='d-flex'>
                                                                            <span className="badge bg-light text-dark cursor-pointer border" onClick={() => handleClonePageModal(val.page)}>
                                                                                <ion-icon name="copy-outline" className="text-danger">
                                                                                </ion-icon>
                                                                                <span>&nbsp;&nbsp;Duplicate</span>
                                                                            </span>
                                                                            <span className="badge bg-danger cursor-pointer ms-1" onClick={(e) => { handleDeletePage(e, val.page) }}>
                                                                                <ion-icon name="trash-outline" className="text-danger">
                                                                                </ion-icon>
                                                                                <span>&nbsp;&nbsp;Delete</span>
                                                                            </span>
                                                                        </div>
                                                                    </a>
                                                                </li>
                                                                {(val.hasOwnProperty('children') && val.children.length !== 0) && val.children.map((val2, k) => {
                                                                    return (
                                                                        <li className={(currentPageName === val2.title) ? 'custom-active ms-3' : 'ms-3'} key={k}>
                                                                            <a href="javascript:void(0)" className=''>
                                                                                <div onClick={() => { handlePageSwitch(val2.page, val2.title) }}>
                                                                                    <ion-icon name="return-down-forward-outline"></ion-icon>&nbsp;&nbsp;&nbsp;&nbsp;{val2.title}
                                                                                    <span className='d-block' style={{'fontSize': '12px', 'lineHeight' : '14px'}}>/{appearance?.pages[val2.page]?.url}</span>

                                                                                </div>
                                                                                <div className='d-flex'>
                                                                                    <span className="badge bg-light text-dark cursor-pointer border" onClick={() => handleClonePageModal(val2.page)}>
                                                                                        <ion-icon name="copy-outline" className="text-danger">
                                                                                        </ion-icon>
                                                                                        <span>&nbsp;&nbsp;Duplicate</span>
                                                                                    </span>
                                                                                    <span className="badge bg-danger cursor-pointer ms-1" onClick={(e) => { handleDeletePage(e, val2.page) }}>
                                                                                        <ion-icon name="trash-outline" className="text-danger">
                                                                                        </ion-icon>
                                                                                        <span>&nbsp;&nbsp;Delete</span>
                                                                                    </span>
                                                                                </div>
                                                                            </a>
                                                                        </li>
                                                                    )
                                                                })}
                                                            </>
                                                        )
                                                    })}
                                                </>

                                            }

                                                {(value.hasOwnProperty('children') && value.children.length === 0) &&
                                                    <li className={(currentPageName === value.title) ? 'custom-active' : ''}>
                                                        <a href="javascript:void(0)">
                                                            <div onClick={() => { handlePageSwitch(value.page, value.title) }}>
                                                                {value.title}
                                                                <span className='d-block' style={{'fontSize': '12px', 'lineHeight' : '14px'}}>/{appearance?.pages[value.page]?.url}</span>
                                                            </div>
                                                            {/* <span className="text-muted">
                                                                <ion-icon name="trash-outline" className="text-danger" onClick={(e) => { handleDeletePage(e, value.page) }}></ion-icon> Manage
                                                            </span> */}
                                                            <div className='d-flex'>
                                                                <span className="badge bg-light text-dark cursor-pointer border" onClick={() => handleClonePageModal(value.page)}>
                                                                    <ion-icon name="copy-outline" className="text-danger">
                                                                    </ion-icon>
                                                                    <span>&nbsp;&nbsp;Duplicate</span>
                                                                </span>
                                                                <span className="badge bg-danger cursor-pointer ms-1" onClick={(e) => { handleDeletePage(e, value.page) }}>
                                                                    <ion-icon name="trash-outline" className="text-danger">
                                                                    </ion-icon>
                                                                    <span>&nbsp;&nbsp;Delete</span>
                                                                </span>
                                                            </div>
                                                        </a>
                                                    </li>
                                                }

                                                {!value.hasOwnProperty('children') &&
                                                    <li className={(currentPageName === value.title) ? 'custom-active' : ''}>
                                                        {/* <a href="javascript:void(0)" onClick={() => { handlePageSwitch(value.page, value.title) }}>
                                                            {value.title}
                                                            <span className="text-muted">Manage</span>
                                                        </a> */}

                                                        {/* <span className="badge bg-primary cursor-pointer" onClick={() => { handlePageSwitch(value.page, value.title) }}>
                                                            <ion-icon name="settings-outline" className="text-danger"></ion-icon> 
                                                            <span className='ps-1'>Settings</span>
                                                        </span> */}
                                                        <a href="javascript:void(0)">
                                                            <div onClick={() => { handlePageSwitch(value.page, value.title) }}>
                                                                {value.title}
                                                                <span className='d-block' style={{'fontSize': '12px', 'lineHeight' : '14px'}}>/{appearance?.pages[value.page]?.url}</span>
                                                            </div>
                                                            <div className='d-flex'>
                                                                <span className="badge bg-light text-dark cursor-pointer border" onClick={() => handleClonePageModal(value.page)}>
                                                                    <ion-icon name="copy-outline" className="text-danger">
                                                                    </ion-icon>
                                                                    <span>&nbsp;&nbsp;Duplicate</span>
                                                                </span>
                                                                <span className="badge bg-danger cursor-pointer ms-1" onClick={(e) => { handleDeletePage(e, value.page) }}>
                                                                    <ion-icon name="trash-outline" className="text-danger">
                                                                    </ion-icon>
                                                                    <span>&nbsp;&nbsp;Delete</span>
                                                                </span>
                                                            </div>
                                                        </a>
                                                    </li>
                                                }
                                            </>)
                                        })}
                                    </ul>
                                </div>
                                {
                                    showAllPages ? <>
                                        <h3 className='px-4'>Hidden pages</h3>
                                        <div className='hiddenPages maxListHieght'>
                                            <ul className="listview link-listview">
                                                {showAllPagesList.map((value, j) => {
                                                    return (<>
                                                        {
                                                            (value?.title?.toLowerCase().includes(searchIn?.toLowerCase()) || value?.url?.toLowerCase().includes(searchIn?.toLowerCase()) || search?.trim().length === 0) ?
                                                            <li className={(currentPageName === value.title) ? 'custom-active' : ''}>
                                                                <a href="javascript:void(0)">
                                                                    <div onClick={() => { handlePageSwitch(value.page, value.title) }}>
                                                                        {value.title}
                                                                        <span className='d-block' style={{'fontSize': '12px', 'lineHeight' : '14px'}}>/{appearance?.pages[value.page]?.url}</span>
                                                                    </div>
                                                                    <div className='d-flex'>
                                                                        <span className="badge bg-light text-dark cursor-pointer border" onClick={() => handleClonePageModal(value.page)}>
                                                                            <ion-icon name="copy-outline" className="text-danger">
                                                                            </ion-icon>
                                                                            <span>&nbsp;&nbsp;Duplicate</span>
                                                                        </span>
                                                                        <span className="badge bg-danger cursor-pointer ms-1" onClick={(e) => { handleDeletePage(e, value.page) }}>
                                                                            <ion-icon name="trash-outline" className="text-danger">
                                                                            </ion-icon>
                                                                            <span>&nbsp;&nbsp;Delete</span>
                                                                        </span>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            :
                                                            <></>
                                                        }
                                                    </>)
                                                })}
                                            </ul>
                                        </div>
                                    </>
                                        :
                                        <></>
                                }
                            </div>
                        </div>
                    </Modal.Body>
                </div>
            </Modal>
        </>
    );
}

const mapStateToProps = ({ utilities, offcanvas, websiteData, auth }) => {
    const { authToken } = auth
    const {
        domains,
        websites,
        userInfo
    } = utilities
    const {
        isSwitchModalVisible,
        switchModalMessage,
        blockID
    } = offcanvas
    const {
        appearance,
        CompleteJSON
    } = websiteData
    return {
        utilitiesDomains: domains,
        utilitiesWebsites: websites,
        utilitiesUserInfo: userInfo,
        isSwitchModalVisible,
        switchModalMessage,
        blockID,
        appearance,
        CompleteJSON,
        authToken
    }
}

const mapDispatchToProps = {
    updateUtilities,
    hideSwitchModal,
    setWebsiteData,
    addPageModal,
    showNavigationOffcanvas,
    showDeleteModal,
    showClonePageModal,
    setADDClonePageProps
}

export default connect(mapStateToProps, mapDispatchToProps)(SwitchModal)