import React, { useEffect, useState, useRef } from 'react'
import { useLocation, useNavigate, Link, useSearchParams } from "react-router-dom"
import { connect } from "react-redux"
import '@splidejs/splide/dist/css/splide.min.css'
import { dynamicBlocks } from '../Blocks/dynamicBlocks'
import axios from '../../AxiosConfig'
import jwt_decode from 'jwt-decode'
import ButtonModal from '../Elements/Modals/ButtonModal'
import ImageModal from '../Elements/Modals/ImageModal'
import AddBlockModal from '../Elements/Modals/AddBlockModal'
import Offcanvas from '../layout/Rightpanel/Offcanvas'
import EditContentOffcanvas from '../layout/Rightpanel/EditContentOffcanvas'
import NotFound from './NotFound'
import { showOffcanvas, hideOffcanvas, showEditOffcanvas, hideEditOffcanvas, showTemplateOffcanvas, showPropertyOffcanvas, showDeleteModal, showPageSetting, showPreviewModal, setResponsiveIframe, showSwitchModal, showAppearance, addPageModal, showPopupBlockOffcanvas, setMainBlockProps, setContentBlockItem, setContentItems, setNewBlockType, showUpdateDataOffcanvas, showAddBlockOffcanvas, showNavigationOffcanvas, setCloneBlockProps, showAddOffcanvas } from '../../features/modal/offcanvasSlice'
import { setWebsiteData, setBlockRules, setLoading } from '../../features/websiteData/websiteDataSlice'
import { setCropperSizes } from '../../features/modal/offcanvasSlice'
import { showAppToast } from '../../features/utilities/appToastSlice'
import ToolBar from '../Elements/Management/ToolBar'
import Properties from '../layout/Rightpanel/Properties'
// import BlockListModal from '../Elements/Modals/BlockListModal'
import AddOffcanvas from '../layout/Rightpanel/AddOffcanvas'
import PageSetting from '../layout/Rightpanel/PageSetting'
import Modal from 'react-bootstrap/Modal'
import moment from "moment"
import FormOffcanvas from '../layout/Rightpanel/FormOffcanvas'
import AddBlockOffcanvas from '../layout/Rightpanel/AddBlockOffcanvas'
import MDEditor from '@uiw/react-md-editor'
import NewEditOffcanvas from '../layout/Rightpanel/newEditOffcanvas'
import ClonePageOffcanvas from '../layout/Rightpanel/clonePageOffcanvas'
import JSONModal from '../Elements/Modals/JSONModal'
import EditHtmlJsCssFileModal from '../Elements/Modals/EditHtmlJsCssFileModal'
import PageSettingsCards from '../Elements/Comps/PageSettingsCards'
import WebsiteGenerater from '../Elements/Comps/WebsiteGenerater'

const GenerateWebsite = ({
    modalVisible,
    modalConfig,
    showDeleteModal,
    offcanvasVisible,
    editOffcanvasVisible,
    offcanvasItems,
    offcanvasMsg,
    offcanvasItemID,
    offcanvasProperties,
    offcanvasBlockID,
    offcanvasBlockType,
    offcanvasIsImageModalVisible,
    offcanvasImageData,
    offcanvasImageID,
    offcanvasIsButtonModalVisible,
    offcanvasButtonData,
    offcanvasButtonID,
    offcanvasIsPropertiesVisible,
    offcanvasPageName,
    websiteDataAppearnace = {},
    websiteDataBlocks = {},
    websiteDataContents = {},
    isAddOffcanvasVisible,
    addMessage,
    showPreviewModal,
    setBlockRules,
    showPageSetting,
    isPageSettingVisible,
    defaultActiveTab,
    device,
    setResponsiveIframe,
    runPartyParticals,
    cropperConfig,
    showSwitchModal,
    showAppearance,
    websites,
    type,
    domains,
    category,
    addPageModal,
    showPopupBlockOffcanvas,
    blocks,
    contents,
    encrypt,
    NewBlockType,
    setMainBlockProps,
    setContentBlockItem,
    setContentItems,
    setCropperSizes,
    loading,
    setLoading,
    functionObject,
    setFunctionObject,
    setNewBlockType,
    showUpdateDataOffcanvas,
    showAddBlockOffcanvas,
    showNavigationOffcanvas,
    setCloneBlockProps,
    showAddOffcanvas,
    CompleteJSON
}) => {
    const iframeRef = useRef(null);

    const [searchParams, setSearchParams] = useSearchParams({ 'url': 'home', 'menu': '' })
    const url = searchParams.get('url');
    const menu = searchParams.get('menu');

    const [comps, setComps] = useState([])
    const [editPage, setEditPage] = useState(false)
    const [urlShow, setUrlShow] = useState(true)
    const [stopParty, setStopParty] = useState(true)
    const [runParty, setRunParty] = useState(true)
    const [cropperConfigData, setCropperConfigData] = useState(null)
    const [showModalSwitch, setShowModalSwitch] = useState(false)
    const [popupButtonFlag, setPopupButtonFlag] = useState(false)
    const [launchValue, setLaunchValue] = useState()
    const [role, setRole] = useState()
    const [aliasDomain, setAliasDomain] = useState()

    const token = localStorage.getItem('token')
    const tokenData = (token) ? jwt_decode(token) : false;
    const selectedDomain = tokenData.SelectedDomain.Domain;
    const domainName = tokenData.SelectedDomain
    const aliasDomainValue = (domainName.AliasDomain !== '' && domainName.AliasDomain !== null) ? domainName.AliasDomain : domainName.Domain
    const roleID = tokenData.userInfo.RoleID

    let navigate = useNavigate()

    useEffect(() => {
        setRole(roleID)
        const comps = renderPage()
        setComps(comps)
        axios.get('/block/rules').then(res => {
            setBlockRules({ blockRules: res.data.data })
        })
    }, [setBlockRules, websiteDataAppearnace, websiteDataBlocks, websiteDataContents, url])

    useEffect(() => {
        setAliasDomain(aliasDomainValue);
    }, [aliasDomainValue])

    useEffect(() => {
        if (cropperConfig === undefined) {
            axios.get('/info/style/cropperConfig').then(res => {
                if (res.data.message === 'Success') {
                    setCropperConfigData(res.data.data);
                    setCropperSizes(res.data.data);
                    localStorage.setItem('cropperConfig', JSON.stringify(res.data.data))
                } else {
                    console.log('SOMETHING WENT WRONG')
                }
            })
        } else {
            setCropperConfigData(cropperConfig)
        }
    }, [cropperConfig]);

    useEffect(() => {
        if (NewBlockType !== null) {
            if (NewBlockType.BlockType === 'CN') {
                const getData = blocks[`${NewBlockType.BlockID}`];
                setMainBlockProps(getData.properties);
                setContentItems(getData.items);
                const itm = JSON.parse(JSON.stringify(contents[getData.items.contents.replace('contents.', '')].items[NewBlockType.ItemNo]));
                itm.button = itm.button.map((items) => {
                    let returnV1 = items;
                    if (returnV1.cta) {
                        returnV1.buttonDetails = encrypt.ctas[returnV1.cta.replace('ctas.', '')].properties;
                    }
                    return returnV1;
                })

                setContentBlockItem(itm);
            } else if (NewBlockType.BlockType === 'SM') {
                const getData = blocks[`${NewBlockType.BlockID}`];
                setMainBlockProps(getData.properties);
                setContentItems(getData.items);
                if (NewBlockType.BlockName === 'SM_CNTC' || NewBlockType.BlockName === 'SM_CNTR') {

                    const itm = JSON.parse(JSON.stringify(getData.items[NewBlockType.ItemNo]));
                    itm.button = itm.button === null ? [] : itm.button.map((items) => {
                        let returnV1 = items;
                        if (returnV1.cta) {
                            returnV1.buttonDetails = encrypt.ctas[returnV1.cta.replace('ctas.', '')].properties;
                        }
                        return returnV1;
                    })

                    setContentBlockItem(itm);
                } else {
                    const itm = JSON.parse(JSON.stringify(getData.items[0]));
                    itm.button = itm.button === null ? [] : itm.button.map((items) => {
                        let returnV1 = items;
                        if (returnV1.cta) {
                            returnV1.buttonDetails = encrypt.ctas[returnV1.cta.replace('ctas.', '')].properties;
                        }
                        return returnV1;
                    })

                    setContentBlockItem(itm);
                }
            } else {

            }
        }
    }, [NewBlockType]);

    useEffect(() => {
        let appWidth = null
        if (device === 'mobile') {
            const appCapsule = document.getElementById("appCapsule")
            if (appCapsule) {
                appCapsule.style.width = "35%"
                appWidth = appCapsule.offsetWidth
            }
        }
        else if (device === 'desktop' || device === undefined || device === '') {
            const appCapsule = document.getElementById("appCapsule")
            if (appCapsule) {
                appCapsule.style.width = "100%"
                appWidth = appCapsule.offsetWidth
            }
        }
        if (appWidth > 411) {
            setUrlShow(true)
        } else {
            setUrlShow(false)
        }

        showPopupBlockOffcanvas({ isPopupBlockVisible: true })

    }, [device, url])

    useEffect(() => {
        if (runPartyParticals) {
            setStopParty(runPartyParticals)
            setRunParty(runPartyParticals)
            setTimeout(function () {
                setStopParty(false)
            }, 10000)

            setTimeout(function () {
                setRunParty(false)
            }, 15000)
        }
    }, [runPartyParticals])

    useEffect(() => {
        showPopupBlockOffcanvas({ isPopupBlockVisible: true })
    }, [])

    useEffect(() => {
        if (websiteDataAppearnace.hasOwnProperty('pages')) {
            let pageId = 'Not Found'
            let pageName = 'Not Found'
            Object.keys(websiteDataAppearnace.pages).map((value, key) => {
                if (websiteDataAppearnace.pages[value].url === url) {
                    pageId = value
                    websiteDataAppearnace.navigation.header.map((v, k) => {
                        if (v.hasOwnProperty('children')) {
                            websiteDataAppearnace.navigation.header[k].children.map((v1, k1) => {
                                if (v1.page === pageId) {
                                    pageName = v1.text
                                }
                            })
                        } else {
                            if (v.page === pageId) {
                                pageName = v.text
                            }
                        }
                    })
                }
            })
            let pageBlocks = []
            const currentPage = pageId
            // console.log(currentPage)
            pageBlocks = websiteDataAppearnace.pages.hasOwnProperty(currentPage) ? websiteDataAppearnace.pages[currentPage].blocks : false
            Object.keys(websiteDataBlocks).map((value, key) => {
                if (value.includes('PL_')) {
                    pageBlocks = [...pageBlocks, value]
                }
            })
            setPopupButtonFlag(false)
            // console.log(pageBlocks)
            if (typeof (pageBlocks) !== 'boolean') {
                pageBlocks.map(value => {
                    if (value.includes('POUP')) {
                        setPopupButtonFlag(true)
                    }
                })
            }
        }

    }, [websiteDataAppearnace, websiteDataBlocks])

    let pageBackground = ''

    const renderPage = () => {
        if (websiteDataAppearnace.hasOwnProperty('pages')) {
            let pageId = 'Not Found'
            let pageName = 'Not Found'
            Object.keys(websiteDataAppearnace.pages).map((value, key) => {
                if (websiteDataAppearnace.pages[value].url === url) {
                    pageId = value
                    websiteDataAppearnace.navigation.header.map((v, k) => {
                        if (v.hasOwnProperty('children')) {
                            websiteDataAppearnace.navigation.header[k].children.map((v1, k1) => {
                                if (v1.page === pageId) {
                                    pageName = v1.text
                                }
                            })
                        } else {
                            if (v.page === pageId) {
                                pageName = v.text
                            }
                        }
                    })
                }
            })
            if (pageId === 'Not Found') {
                return <div><NotFound /></div>
            } else {
                pageBackground = (websiteDataAppearnace.hasOwnProperty('pages') ? websiteDataAppearnace.pages[pageId].properties.backgroundValue : '#eeeeee')
                const currentPage = pageId
                let pageBlocks = []
                pageBlocks = websiteDataAppearnace.pages.hasOwnProperty(currentPage) ? websiteDataAppearnace.pages[currentPage].blocks : false
                Object.keys(websiteDataBlocks).map((value, key) => {
                    if (value.includes('PL_')) {
                        pageBlocks = [...pageBlocks, value]
                    }
                })
                if (pageBlocks) {
                    let blockSeq = 0
                    const blockCount = pageBlocks.length
                    return pageBlocks.map((blockID, key) => {
                        const blockJson = websiteDataBlocks.hasOwnProperty(blockID) ? websiteDataBlocks[blockID] : false
                        if (blockJson) {
                            const blockProperties = blockJson.properties
                            let blockComponent = blockProperties.styleID
                            const blockPropsStyle = blockProperties.style;
                            const blockType = blockComponent.substring(0, 2)
                            let blockStyleArr = blockComponent.split('_')
                            const blockStyle = blockStyleArr[2]
                            const blockItems = getContentItems(blockID)
                            const itemCount = getItemCount(blockID)
                            const blockName = blockID.split('_')[1]
                            // console.log(blockComponent)
                            if (dynamicBlocks.hasOwnProperty(blockComponent)) {
                                // console.log('IF')
                                let Component = dynamicBlocks[blockComponent]
                                // console.log(Component)
                                blockSeq++
                                blockSeq = (blockSeq === blockCount) ? 'last' : blockSeq
                                if (blockType === 'PL') {
                                    return (
                                        <div data-color-mode="light" key={key}>
                                            <div className="dynamicRender" style={{ marginTop: '-15px' }}>
                                                <Component properties={blockProperties} items={blockItems} seq={blockSeq} blockID={blockID} blockType={blockType} />
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div data-color-mode="light" key={key}>
                                            {
                                                !blockName.includes('POUP') &&
                                                <ToolBar show={true} blockType={blockType} seq={blockSeq} items={blockItems} contentCount={itemCount} properties={blockProperties} blockID={blockID} cssID={key} />
                                            }
                                            <div className="dynamicRender" style={{ marginTop: '-15px' }}>
                                                <Component properties={blockProperties} items={blockItems} seq={blockSeq} blockID={blockID} blockType={blockType} />
                                            </div>
                                        </div>)
                                }
                            } else {
                                // console.log('ELSE')

                                let Component = dynamicBlocks[blockStyleArr[0] + '_' + blockStyleArr[1] + '_DFL1']
                                // console.log(blockType)
                                blockSeq++
                                blockSeq = (blockSeq === blockCount) ? 'last' : blockSeq
                                if (blockType === 'PL') {
                                    return (
                                        <div data-color-mode="light" key={key}>
                                            <div className="dynamicRender" style={{ marginTop: '-15px' }}>
                                                <Component properties={blockProperties} items={blockItems} seq={blockSeq} blockID={blockID} blockType={blockType} />
                                            </div>
                                        </div>
                                    )
                                } else if (blockComponent.split('_').length > 3) {
                                    if (dynamicBlocks.hasOwnProperty(blockPropsStyle)) {
                                        ;
                                        let Component = dynamicBlocks[blockPropsStyle];
                                        blockSeq++
                                        blockSeq = (blockSeq === blockCount) ? 'last' : blockSeq
                                        return (
                                            <div data-color-mode="light" key={key}>
                                                <ToolBar show={true} blockType={blockType} seq={blockSeq} items={blockItems} contentCount={itemCount} properties={blockProperties} blockID={blockID} cssID={key} />
                                                <div className="dynamicRender" style={{ marginTop: '-15px' }}>
                                                    <Component properties={blockProperties} items={blockItems} seq={blockSeq} blockID={blockID} blockType={blockType} />
                                                </div>
                                            </div>)
                                    }
                                } else {
                                    return (
                                        <div data-color-mode="light" key={key}>
                                            {
                                                blockName !== 'POPUP' &&
                                                <ToolBar show={true} blockType={blockType} seq={blockSeq} items={blockItems} contentCount={itemCount} properties={blockProperties} blockID={blockID} cssID={key} />
                                            }
                                            <div className="dynamicRender" style={{ marginTop: '-15px' }}>
                                                <Component properties={blockProperties} items={blockItems} seq={blockSeq} blockID={blockID} blockType={blockType} />
                                            </div>
                                        </div>
                                    )
                                }
                            }
                        } else {
                            return <div className="dynamicRender" key={key}>No Block Found</div>
                        }
                    })
                } else {
                    return false
                }


            }
        } else {
            return <div><NotFound /></div>
        }
    }

    const getItemCount = (blockID) => {
        const blockJson = websiteDataBlocks.hasOwnProperty(blockID) ? websiteDataBlocks[blockID] : false
        if (blockJson) {
            const blockProperties = blockJson.properties
            const blockComponent = blockProperties.styleID
            const blockType = blockComponent.substring(0, 2)
            if (blockType === 'CN') {
                const contentType = blockJson.items.contents.split('.')[0]
                const contentID = blockJson.items.contents.split('.')[1]
                let contentItems = websiteDataContents.hasOwnProperty(contentID) ? websiteDataContents[contentID].items : false
                return contentItems
            } else {
                return blockJson.items
            }
        } else {
            return false
        }
    }

    const getContentItems = (blockID) => {
        const blockJson = websiteDataBlocks.hasOwnProperty(blockID) ? websiteDataBlocks[blockID] : false
        if (blockJson) {
            const blockProperties = blockJson.properties
            const blockComponent = blockProperties.styleID
            const blockType = blockComponent.substring(0, 2)
            if (blockType === 'CN') {
                const contentType = blockJson.items.contents.split('.')[0]
                const contentID = blockJson.items.contents.split('.')[1]
                // const contents = websiteDataBlocks.hasOwnProperty(blockID) ? websiteDataBlocks[blockID] : false
                let contentItems = websiteDataContents.hasOwnProperty(contentID) ? websiteDataContents[contentID].items : false
                let reqItems = blockJson.items.numItems
                const filter = blockJson.items.itemFilters
                if (filter === 'all') {
                    return contentItems
                }
                if (filter === 'last') {
                    let arr1 = []
                    contentItems.forEach(element => {
                        arr1.unshift(element)
                    });
                    contentItems = arr1
                    if (contentItems.length <= reqItems) {
                        return contentItems
                    }
                }
                if (filter === 'first') {
                    if (contentItems.length <= reqItems) {
                        return contentItems
                    }
                }
                if (filter === 'random') {
                    var randIndexArr = []
                    if (reqItems >= contentItems.length) {
                        while (randIndexArr.length < contentItems.length) {
                            var r = Math.floor(Math.random() * contentItems.length)
                            if (randIndexArr.indexOf(r) === -1) randIndexArr.push(r)
                        }
                        reqItems = contentItems.length
                    } else {
                        while (randIndexArr.length < reqItems) {
                            var r = Math.floor(Math.random() * contentItems.length)
                            if (randIndexArr.indexOf(r) === -1) randIndexArr.push(r)
                        }
                    }
                }
                var temp = []
                for (let index = 0; index < reqItems; index++) {
                    const itemIndex = (filter === 'random') ? randIndexArr[index] : index
                    if (index < contentItems.length) {
                        temp[index] = contentItems[itemIndex]
                    }
                }
                return temp
            } else {
                return blockJson.items
            }
        } else {
            return false
        }
    }

    renderPage(offcanvasPageName)

    const fetchWebsiteJSON = () => {
        const webDoc = CompleteJSON; //localStorage.getItem('website')
        const res = webDoc // ? JSON.parse(atob(webDoc)) : false
        if (res) {
            return res
        } else {
            return false
        }
    }
    const websiteJSON = fetchWebsiteJSON()

    const getBlockDetails = (blockUniqueID) => {
        const { styleID, style } = blocks[blockUniqueID].properties;
        const { properties } = blocks[blockUniqueID];
        let items = blocks[blockUniqueID].items;
        if (blockUniqueID.slice(0, 2) === 'CN') {
            items = JSON.parse(JSON.stringify(contents[items.contents.replace('contents.', '')].items));
        }
        return { properties, style, items, styleID }
    }

    const handleNewEditItem = (msg, blockID, ItemNo, ItemEdit) => {
        const { properties, style, items, styleID } = getBlockDetails(blockID);
        if (ItemEdit && blockID.slice(0, 7) !== 'CN_CRSL' && blockID.slice(0, 7) !== 'CN_CNTR') {
            setNewBlockType({ 'BlockType': blockID.slice(0, 2), 'BlockID': blockID, 'BlockName': blockID.slice(0, 7), 'ItemNo': (ItemNo === undefined || ItemNo === null) ? 0 : ItemNo, 'Style': style, 'StyleID': styleID });
        } else {
            setNewBlockType({ 'BlockType': blockID.slice(0, 2), 'BlockID': blockID, 'BlockName': blockID.slice(0, 7), 'ItemNo': (ItemNo === undefined || ItemNo === null) ? 0 : ItemNo });
        }
        if (blockID.slice(0, 2) === 'CN') {

            if (ItemNo === undefined || ItemNo === null) {
                showUpdateDataOffcanvas({
                    isEditVisible: true,
                    itemID: 0,
                    items: items,
                    blockID: blockID,
                    properties: properties,
                    ActiveOffcanvasParentTab: 'Layers',
                    ActiveOffcanvasChildTab: 'Block'
                })
            } else {
                showUpdateDataOffcanvas({
                    isEditVisible: true,
                    itemID: Number(ItemNo),
                    items: items,
                    blockID: blockID,
                    properties: properties
                })
            }

        } else {
            if (blockID.slice(0, 7) === 'SM_HEAD' || blockID.slice(0, 7) === 'SM_FOOT') {
                showUpdateDataOffcanvas({
                    isEditVisible: true,
                    items: items,
                    blockID: blockID,
                    properties: properties,
                    blockType: blockID.slice(0, 2),
                    msg: msg,
                    ActiveOffcanvasParentTab: 'Layers',
                    ActiveOffcanvasChildTab: 'Block'
                })
            } else {
                showUpdateDataOffcanvas({
                    isEditVisible: true,
                    items: items,
                    blockID: blockID,
                    properties: properties,
                    blockType: blockID.slice(0, 2),
                    msg: msg,
                    ActiveOffcanvasParentTab: 'Layers',
                    ActiveOffcanvasChildTab: 'Content'
                })
            }
        }
    }

    const addNewBlock = (msg, blockID) => {
        showAddBlockOffcanvas({ isAddBlockVisible: true, blockName: blockID })
    }

    const handleAddNewItem = (msg, blockID, contentPosition) => {
        showAddOffcanvas({ isAddOffcanvasVisible: true, addMessage: 'Content', blockid: blockID, contentPosition: contentPosition })
    }

    const handleDeleteBlock = (msg, blockID) => {
        showDeleteModal({ isDeleteVisible: true, deleteMessage: 'Are your sure? You want to delete this block?', triggerMessage: 'block', deleteID: blockID })
    }

    const handleBlockSeq = (msg, blockID, pageId, direction, steps) => {
        setLoading(true);
        axios.put('/block/move/' + selectedDomain + '/' + blockID + '?page=' + pageId + '&direction=' + direction + '&step=1').then(res => {
            if (res.data.message === 'Success') {
                setWebsiteData(res.data.data)
                showAppToast({ toastMessage: 'Moved ' + direction, background: 'success', timeout: '1000', icon: 'checkmark-circle-outline', position: 'toast-center' })
                setLoading(false);
            } else {
                showAppToast({ toastMessage: 'FAIL', background: 'danger', timeout: '1000', icon: 'close-circle-outline', position: 'toast-center' })
                setLoading(false)
            }
        })
    }

    const handleDeleteContent = (msg, deleteItemID, contentName) => {
        showDeleteModal({
            isDeleteVisible: true,
            deleteMessage: 'Are your sure? You want to delete this content',
            triggerMessage: 'content',
            deleteID: contentName,
            deleteItemID: deleteItemID
        })
    }

    const showNavigationPopup = (msg, blockID) => {
        showNavigationOffcanvas({ isNavigationVisible: true, triggerMessage: '', blockID: blockID, clonningblock: true });
        setCloneBlockProps({ 'DomainName': selectedDomain, 'BlockID': blockID });
    }

    function parseURL(url) {
        const urlObj = new URL(url);

        const domain = urlObj.hostname;

        const path = urlObj.pathname;
        let firstPageUrl = 'index';
        if (websiteDataAppearnace.pages && Object?.keys(websiteDataAppearnace?.pages).length > 0) {
            const one = Object.keys(websiteDataAppearnace.pages).map((page, i) => {
                // console.log(page);
                if (i === 0) firstPageUrl = websiteDataAppearnace.pages[page].url
                return page
            })
        }
        const pageName = path === '/' ? firstPageUrl : path.substring(1);

        return pageName;
    }

    useEffect(() => {
        const handleIframeMessage = (event) => {

            if (url && url !== '' && iframeRef && iframeRef.current && event.source === iframeRef.current.contentWindow) {

                const parseData = event.data;
                if (parseData.includes('edit_modal')) {
                    const splitData = parseData.replace('edit_modal:', '').split('|');
                    handleNewEditItem("msg", splitData[0], splitData[1]);
                } else if (parseData.includes('delete_modal')) {
                    const splitData = parseData.replace('delete_modal:', '').split('|');
                    handleDeleteBlock("msg", splitData[0]);
                } else if (parseData.includes('newblock_modal')) {
                    const splitData = parseData.replace('newblock_modal:', '').split('|');
                    addNewBlock("msg", splitData[0]);
                } else if (parseData.includes('newItem_modal')) {
                    const splitData = parseData.replace('newItem_modal:', '').split('|');
                    handleAddNewItem("msg", splitData[0], 'last')
                } else if (parseData.includes('copyblock_modal')) {
                    const splitData = parseData.replace('copyblock_modal:', '').split('|');
                    showNavigationPopup("msg", splitData[0])
                } else if (parseData.includes('moveDown_modal')) {
                    const splitData = parseData.replace('moveDown_modal:', '').split('|');
                    handleBlockSeq("msg", splitData[0], splitData[1], 'down');
                } else if (parseData.includes('moveUp_modal')) {
                    const splitData = parseData.replace('moveUp_modal:', '').split('|');
                    handleBlockSeq("msg", splitData[0], splitData[1], 'up');
                } else if (parseData.includes('editItem_modal')) {
                    const splitData = parseData.replace('editItem_modal:', '').split('|');
                    console.log(splitData);
                    handleNewEditItem("msg", splitData[0], splitData[2], true);
                } else if (parseData.includes('deleteItem_modal')) {
                    const splitData = parseData.replace('deleteItem_modal:', '').split('|');
                    handleDeleteContent("msg", splitData[2], splitData[3]);
                }


                // else if (parseData.includes('webweb_page_url')) {
                //     const newPageURL = parseData.replace('webweb_page_url:', '');
                //     navigate(
                //         '/build?url=' + parseURL(newPageURL)
                //     )

                // }
            }
        };

        window.addEventListener('message', handleIframeMessage);

        return () => {
            window.removeEventListener('message', handleIframeMessage);
        };
    }, [blocks, websiteDataAppearnace, iframeRef]);

    const isPageId = menu.includes('SM_PAGE');

    const [AllPagesArray, setAllPagesArray] = useState([]);
    useEffect(() => {
        if (isPageId && websiteDataAppearnace?.navigation?.header && menu !== '') {
            const filtered = websiteDataAppearnace?.navigation?.header.filter((h) => h.page === menu);
            if (filtered.length > 0 && filtered[0].children && filtered[0].children.length > 0) {
                // console.log(filtered, isPageId);
                setAllPagesArray(filtered[0].children);
            }
        }
    }, [isPageId, websiteDataAppearnace, url]);

    const [pageHtmlData, setPageHtmlData] = useState('');
    const [htmlSourceCode, setHtmlSourceCode] = useState('');

    // const [bodyTopSection, setBodyTopSection] = useState('');
    // const [bodyBottomSection, setBodyBottomSection] = useState('');

    const getAllHtmlData = (pageName = '') => {
        const pageUrl = pageName === '' ? `https://${domainName.Domain}?editable=0987` : `https://${domainName.Domain}/${pageName}?editable=0987`;
        axios.get(pageUrl)
            .then(res => {
                if(res.data) {
                    // console.log(res.data);
                    setPageHtmlData(res.data)
                }
            })
    }

    useEffect(() => {
        getAllHtmlData(url);
    }, [url]);

    useEffect(() => {
        console.log(loading);
      if(loading) {
        getAllHtmlData(url);
      }
    }, [loading])
    

    const [WebsiteSections, setWebsiteSections] = useState([]);

    // const StartString = '<!-- Body Started -->';
    // const EndString = '<!-- Body Completed -->';

    useEffect(() => {
        if(pageHtmlData.trim().length > 0) {
            // const htmlLength = pageHtmlData.length;\
            const splited = pageHtmlData.split("<!--SPLIT-->");
            setWebsiteSections(splited);
            // const bodyStarteAt = pageHtmlData.indexOf(bodyStartString);
            // const bodyEndeAt = pageHtmlData.indexOf(bodyEndString) + bodyEndString.length;
            // console.log(pageHtmlData.length);
            // console.log(pageHtmlData.slice(4, bodyStarteAt));
            // console.log(pageHtmlData.slice(bodyEndeAt, htmlLength));
        }
    }, [pageHtmlData]);

    const btnSectionHTML = (BlockId, PageID) => {
        const HTMLSec = `<section class="position-relative main-class-section">
        <div class="container-fluid d-flex justify-content-center position-absolute subsection px-0">
            <div class="row w-100">
                <div class="col-4 col-md-4 d-flex left-div px-0 ps-1"> <button id="dropdown-basic" aria-expanded="false"
                        type="button" title="Move Block Up"
                        class="box-shadow-buttons rounded me-1 toolbar-btn btn btn-light btn-sm justify-content-center d-flex all-buttons"
                        onclick="triggerModal('MoveUpBlock','${BlockId}', '${PageID}')"> <i
                            class="fa fa-arrow-up align-self-center icons-app-top" aria-hidden="true"> </i> <span
                            class="d-none d-lg-block mx-0 mx-lg-1 align-self-center badge-text-size">Move Up </span>
                    </button> <button id="dropdown-basic" aria-expanded="false" type="button" title="Move Block Down"
                        class="box-shadow-buttons rounded me-1 toolbar-btn btn btn-light btn-sm justify-content-center d-flex all-buttons"
                        onclick="triggerModal('MoveDownBlock','${BlockId}', '${PageID}')"> <i
                            class="fa fa-arrow-down align-self-center icons-app-top" aria-hidden="true"> </i> <span
                            class="d-none d-lg-block mx-0 mx-lg-1 align-self-center badge-text-size">Move Down </span>
                    </button> <button id="dropdown-basic" aria-expanded="false" type="button" title="Copy Block"
                        class="box-shadow-buttons rounded me-1 toolbar-btn btn btn-light btn-sm justify-content-center d-flex all-buttons"
                        onclick="triggerModal('CopyBlock','${BlockId}', '${PageID}')"> <i
                            class="fa fa-clone align-self-center icons-app-top" aria-hidden="true"> </i> <span
                            class="d-none d-lg-block mx-0 mx-lg-1 align-self-center badge-text-size">Copy </span> </button>
                </div>
                <div class="col-4 col-md-4 justify-content-center d-flex"> <button id="dropdown-basic-middle-btn"
                        aria-expanded="false" title="Add New Block" type="button"
                        class="btn btn-primary btn btn-primary btn-sm middle-button"
                        onclick="triggerModal('AddNewBlock','${BlockId}', '${PageID}')"> <i class="fa fa-plus"
                            aria-hidden="true"></i> </button> </div>
                <div class="col-4 col-md-4 d-flex left-div justify-content-end px-0"> <button id="dropdown-basic"
                        aria-expanded="false" type="button" title="Add New Item"
                        class="box-shadow rounded me-1 toolbar-btn btn btn-blue btn-sm justify-content-center d-flex all-buttons all-buttons"
                        onclick="triggerModal('AddNewItem', '${BlockId}', '${PageID}')"> <i
                            class="fa fa-plus align-self-center icons-app" aria-hidden="true"></i> <span
                            class="d-none d-lg-block mx-0 mx-lg-1 align-self-center badge-text-size">Add </span> </button>
                    <button id="dropdown-basic" aria-expanded="false" type="button" title="Edit Block"
                        class="box-shadow rounded me-1 toolbar-btn btn btn-success btn-sm justify-content-center d-flex all-buttons"
                        onclick="triggerModal('EditBlock','${BlockId}', '${PageID}')"> <i
                            class="fa fa-pencil align-self-center icons-app" aria-hidden="true"></i> <span
                            class="d-none d-lg-block mx-0 mx-lg-1 align-self-center badge-text-size">Edit </span> </button>
                    <button id="dropdown-basic" aria-expanded="false" type="button" title="Delete Block"
                        class="box-shadow rounded me-1 toolbar-btn btn btn-danger btn-sm justify-content-center d-flex all-buttons"
                        onclick="triggerModal('DeleteBlock','${BlockId}', '${PageID}')"> <i
                            class="fa fa-trash align-self-center icons-app" aria-hidden="true"></i> <span
                            class="d-none d-lg-block mx-0 mx-lg-1 align-self-center badge-text-size">Delete </span>
                    </button> </div>
            </div>
        </div>
    </section>`
        return HTMLSec
    }

    useEffect(() => {
        if(WebsiteSections.length > 0 && iframeRef.current) {
            const NewHtml =  WebsiteSections.map((element, i) => {
                const buttonHtml = '<section><button class="btn btn-primary">Button</button></section>';
                // if(i === 1) {
                // }
                let combineWithElement = element;
                if(i >  0 && i < (WebsiteSections.length - 1)) {
                    const positionOfCommentClos = element.indexOf('-->');
                    const getBlockDetailsString = element.slice(0, positionOfCommentClos).split('|');
                    combineWithElement = btnSectionHTML(getBlockDetailsString[2], getBlockDetailsString[1]) + element
                }
                return combineWithElement;
            });
            // iframeRef.current.contentDocument.innerHTML = NewHtml.join('');
            setHtmlSourceCode(NewHtml.join(''));
        }
    }, [WebsiteSections, iframeRef])
    

    return (
        <div id="appCapsule" className="pb-0" style={{ backgroundColor: pageBackground, marginTop: '30px' }}>
            {
                isPageId ?
                    <>
                        <div className="section mb-2 mt-2">
                            <PageSettingsCards pageList={AllPagesArray} openPageSettings />
                        </div>
                    </>
                    :
                    <>  
                        <iframe srcDoc={htmlSourceCode} ref={iframeRef} className='iframeSize d-none' />
                        {/* <WebsiteGenerater htmlCode={pageHtmlData} reference={iframeRef} /> */}
                        {/* <iframe
                            src={!loading ? `https://${domainName.Domain}/${url}?token=123456` : `https://${domainName.Domain}${url}?token=123456`}
                            className={`iframeSize ${(menu && menu !== '') ? 'd-none' : ''}`}
                            ref={iframeRef} /> */}
                    </>
            }
            <ButtonModal show={offcanvasIsButtonModalVisible} buttonData={offcanvasButtonData} buttonID={offcanvasButtonID}></ButtonModal>
            <ImageModal show={offcanvasIsImageModalVisible} imageData={offcanvasImageData} imageID={offcanvasImageID} contentBlockID={offcanvasBlockID}></ImageModal>
            <AddBlockModal config={modalConfig} show={modalVisible}></AddBlockModal>
            {offcanvasVisible &&
                <Offcanvas show={offcanvasVisible} items={offcanvasItems} properties={offcanvasProperties} blockID={offcanvasBlockID} blockType={offcanvasBlockType} itemID={offcanvasItemID} message={offcanvasMsg}></Offcanvas>
            }
            <Properties show={offcanvasIsPropertiesVisible}></Properties>
            <AddOffcanvas show={isAddOffcanvasVisible} message={addMessage}></AddOffcanvas>
            <PageSetting show={isPageSettingVisible} message={defaultActiveTab} />
            <FormOffcanvas></FormOffcanvas>
            {websiteDataAppearnace !== null && websiteDataAppearnace !== undefined && Object.keys(websiteDataAppearnace).length > 0 &&
                <AddBlockOffcanvas></AddBlockOffcanvas>
            }
            <NewEditOffcanvas show={editOffcanvasVisible} items={offcanvasItems} properties={offcanvasProperties} blockID={offcanvasBlockID} blockType={offcanvasBlockType} itemID={offcanvasItemID} message={offcanvasMsg}></NewEditOffcanvas>
            <ClonePageOffcanvas></ClonePageOffcanvas>
            <JSONModal />
            <EditHtmlJsCssFileModal />
        </div>
    )
}

const mapStateToProps = ({ offcanvas, websiteData, utilities }) => {
    const {
        isVisible,
        isEditVisible,
        blockID,
        items,
        msg,
        itemID,
        properties,
        blockType,
        isImageModalVisible,
        imageData,
        imageID,
        isButtonModalVisible,
        buttonData,
        buttonID,
        isPropertiesVisible,
        pageName,
        isAddOffcanvasVisible,
        addMessage,
        isPageSettingVisible,
        defaultActiveTab,
        runPartyParticals,
        NewBlockType
    } = offcanvas
    const {
        appearance: websiteDataAppearnace,
        blocks: websiteDataBlocks,
        contents: websiteDataContents,
        blocks,
        contents,
        encrypt,
        blockRules,
        loading,
        CompleteJSON
    } = websiteData
    const {
        device,
        websites,
        domains
    } = utilities
    return {
        offcanvasVisible: isVisible,
        editOffcanvasVisible: isEditVisible,
        offcanvasBlockID: blockID,
        offcanvasItems: items,
        offcanvasMsg: msg,
        offcanvasItemID: itemID,
        offcanvasProperties: properties,
        offcanvasBlockType: blockType,
        offcanvasIsImageModalVisible: isImageModalVisible,
        offcanvasImageData: imageData,
        offcanvasImageID: imageID,
        offcanvasIsButtonModalVisible: isButtonModalVisible,
        offcanvasButtonData: buttonData,
        offcanvasButtonID: buttonID,
        offcanvasIsPropertiesVisible: isPropertiesVisible,
        offcanvasPageName: pageName,
        websiteDataAppearnace,
        websiteDataBlocks,
        websiteDataContents,
        isAddOffcanvasVisible,
        blocks,
        contents,
        encrypt,
        NewBlockType,
        addMessage,
        blockRules,
        isPageSettingVisible,
        defaultActiveTab,
        device,
        runPartyParticals,
        utilitiesWebsites: websites,
        utilitiesDomains: domains,
        loading
    }
}

const mapDispatchToProps = {
    showOffcanvas,
    hideOffcanvas,
    showEditOffcanvas,
    hideEditOffcanvas,
    showTemplateOffcanvas,
    showPropertyOffcanvas,
    showDeleteModal,
    setWebsiteData,
    showAppToast,
    setBlockRules,
    showPageSetting,
    showPreviewModal,
    setResponsiveIframe,
    showSwitchModal,
    showAppearance,
    addPageModal,
    showPopupBlockOffcanvas,
    setMainBlockProps,
    setContentBlockItem,
    setContentItems,
    setCropperSizes,
    setLoading,
    setNewBlockType,
    showUpdateDataOffcanvas,
    showAddBlockOffcanvas,
    showDeleteModal,
    showNavigationOffcanvas,
    setCloneBlockProps,
    showAddOffcanvas
}

export default connect(mapStateToProps, mapDispatchToProps)(GenerateWebsite)